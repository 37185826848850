import { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import CopilotIcon from '../assets/icons/copilot.svg';
import PulseIcon from '../assets/icons/pulse.svg';
import { capitalizeWords } from '../common/util';
import { useAuthStore } from 'state/auth';
import { useTimeTrackerStore } from 'state/useTimeTracker';
const Sidebar = ({ account }) => {
  const { token } = useAuthStore.getState();
  const hasTimeTrackerAccess = useTimeTrackerStore((state) => state.hasTimeTrackerAccess);
  const [isCollapsed, setIsCollapsed] = useState(
    window.location.href.split('/').length > 4 &&
    window.location.href.split('/')[3] !== 'copilot'
  );
  const navigate = useNavigate();
  const location = useLocation();

  const menuList =
    account === 'mensa' || account === 'valeo'
      ? [{ name: 'copilot', logo: CopilotIcon }]
      : [{ name: 'pulse', logo: PulseIcon }];

  const subMenu = {
    pulse: [],
    copilot: ['conversations', 'configure'],
  };
  if (hasTimeTrackerAccess(account)) {
    subMenu['pulse'].push('time-diary');
  }
  const [expandedMenu, setExpandedMenu] = useState('');

  const toggleMenu = (menuItemName) => {
    const newPath = `/${menuItemName}`;
    navigate(newPath);
    setExpandedMenu(expandedMenu === menuItemName ? '' : menuItemName);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const currentMenu = pathSegments[1];
    setIsCollapsed(pathSegments.length > 2 && currentMenu !== 'copilot');
    setExpandedMenu(currentMenu);
  }, [location]);

  return (
    <div
      className={`transition-all ease-in-out duration-300 ${isCollapsed ? 'w-16' : 'w-40'} overflow-hidden bg-primaryColor`}
    >
      <div className="gap-1 grid grid-rows-1">
        {menuList.map((menuItem) => (
          <div key={menuItem.name}>
            <div
              onClick={() => toggleMenu(menuItem.name)}
              onKeyUp={(e) => e.key === 'Enter' && toggleMenu(menuItem.name)}
              tabIndex="0"
              className={`flex items-center justify-between text-sm font-medium cursor-pointer p-4 no-underline ${expandedMenu === menuItem.name
                ? 'text-white font-bold bg-highlightColor'
                : 'text-[#9F9F9F] bg-transparent'
                }`}
            >
              <span className="pl-2 flex gap-1">
                <img src={menuItem.logo} alt={menuItem.name} />
                {isCollapsed ? '' : capitalizeWords(menuItem.name)}
              </span>
              {subMenu[menuItem.name].length > 0 &&
                !isCollapsed &&
                (expandedMenu === menuItem.name ? (
                  <ChevronUp />
                ) : (
                  <ChevronDown />
                ))}
            </div>
            {expandedMenu === menuItem.name &&
              !isCollapsed &&
              subMenu[menuItem.name].map((subMenuItem) => (
                <NavLink
                  key={subMenuItem}
                  to={`/${menuItem.name}/${subMenuItem}`}
                  className={({ isActive }) =>
                    `flex items-center cursor-pointer text-sm no-underline ${isActive
                      ? 'text-white bg-highlightColor font-bold p-4 pl-8'
                      : 'text-[#9F9F9F] font-normal bg-transparent py-4 pl-8'
                    }`
                  }
                >
                  {capitalizeWords(subMenuItem)}
                </NavLink>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
