import React, { useEffect, useCallback, memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import Home from './Home';
import DayView from './DayView';
import { useTimeTrackerStore } from '../../../state/useTimeTracker';

const TimeTracker = () => {
  const {
    fetchAllAgents,
    fetchMonthlyLogs,
    month,
    year,
    selectedAgent
  } = useTimeTrackerStore(state => ({
    fetchAllAgents: state.fetchAllAgents,
    fetchMonthlyLogs: state.fetchMonthlyLogs,
    month: state.month,
    year: state.year,
    selectedAgent: state.selectedAgent
  }), shallow);

  const stableFetchAllAgents = useCallback(fetchAllAgents, [fetchAllAgents]);
  const stableFetchMonthlyLogs = useCallback(fetchMonthlyLogs, [fetchMonthlyLogs]);

  useEffect(() => {
    stableFetchAllAgents();
  }, [stableFetchAllAgents]);

  useEffect(() => {
    if (selectedAgent !== '' && selectedAgent !== null) {
      stableFetchMonthlyLogs();
    }
  }, [year, month, selectedAgent, stableFetchMonthlyLogs]);

  return (
    <>
      <div className="flex flex-col bg-bgColor w-full min-h-[100%]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="day-view" element={<DayView />} />
        </Routes>
      </div>
    </>
  );
};

export default memo(TimeTracker);