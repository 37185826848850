import { useEffect, useState } from 'react';
import { useTimeTrackerStore } from 'state/useTimeTracker';

const SetTimeLogModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [hours, setHours] = useState('0');
    const [minutes, setMinutes] = useState('0');    
    useEffect(() => {
        if (isOpen) {
            const currentDate = new Date();
            setSelectedDate(currentDate);
            handleDateChange({ target: { value: currentDate.toISOString().split('T')[0] } });
        }
    }, [isOpen]);

    const handleDateChange = async (e) => {
        const newDate = new Date(e.target.value);
        setSelectedDate(newDate);

        const { dailyLogs } = useTimeTrackerStore.getState();
        const logForDate = dailyLogs.find(log => log.day === newDate.getDate());

        if (logForDate) {
            const totalMinutes = Math.floor(logForDate.duration / (60 * 1000));
            setHours(Math.floor(totalMinutes / 60).toString());
            setMinutes((totalMinutes % 60).toString());
        } else {
            setHours('0');
            setMinutes('0');
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoading) return; // Prevent multiple submissions
        const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
        const durationInMilliseconds = totalMinutes * 60 * 1000;
        await onSubmit({
            date: selectedDate.getDate(),
            month: selectedDate.getMonth() + 1, // Adding 1 because getMonth() returns 0-11
            year: selectedDate.getFullYear(),
            duration: durationInMilliseconds
        });
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="text-xl font-bold mb-4">Set Time Log</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-2">Date:</label>
                        <input
                            type="date"
                            value={selectedDate.toISOString().split('T')[0]}
                            onChange={handleDateChange}
                            className="border p-2 rounded w-full"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Present Time Log:</label>
                        <div className="flex space-x-2">
                            <div className="flex flex-col">
                                <label className="mb-1">Hours:</label>
                                <input
                                    type="number"
                                    value={hours}
                                    onChange={(e) => setHours(e.target.value)}
                                    className="border p-2 rounded w-32"
                                    min="0"
                                    max="24"
                                    placeholder="0-24"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label className="mb-1">Minutes:</label>
                                <input
                                    type="number"
                                    value={minutes}
                                    onChange={(e) => setMinutes(e.target.value)}
                                    className="border p-2 rounded w-32"
                                    min="0"
                                    max="60"
                                    placeholder="0-60"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 rounded"
                            disabled={isLoading}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className={`px-4 py-2 bg-slateblue text-white rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Setting Log...' : 'Set Log'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SetTimeLogModal;
