import React, { useEffect, memo } from 'react';
import TopBar from '../../../../components/TopBar';
import TopBarContent from './components/TopBarContent';
import Overview from '../components/Overview';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SessionView from './components/SessionView';
import { useTimeTrackerStore } from '../../../../state';
import { shallow } from 'zustand/shallow';

const AgentView = () => {
  const [searchParams] = useSearchParams();
  const { fetchDailyImage, resetImages } = useTimeTrackerStore(
    (state) => ({
      fetchDailyImage: state.fetchDailyImage,
      resetImages: state.resetImages,
    }),
    shallow
  );
  const agentId = searchParams.get('agentId');
  const day = searchParams.get('day');
  const month = searchParams.get('month'); //this is zero indexed
  const year = searchParams.get('year');
  const navigate = useNavigate();

  useEffect(() => {
    //if any is not present navigate to home
    if (!agentId || !day || !month || !year) {
      navigate('/pulse/time-diary');
      return;
    }
    fetchDailyImage(new Date(year, month, day));
  }, [agentId, day, month, year]);

  useEffect(() => {
    return () => {
      resetImages();
    };
  }, []);

  return (
    <>
      <TopBar>
        <TopBarContent />
      </TopBar>
      <div className="p-5">
        <Overview />
        <SessionView />
      </div>
    </>
  );
};

export default memo(AgentView);
