import baseAPI from './baseAPI';

export const getAllAgentsApi = async (token) => {
  const resp = await baseAPI.get('/tracker/agents', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!resp.ok) {
    throw new Error('Failed to fetch agents');
  }
  return await resp.json();
};

export const getAgentMonthlyLog = async (
  agentId,
  account,
  month, //this is 0 based
  year,
  token
) => {
  const resp = await baseAPI.get(
    `/tracker/logs?agentId=${agentId}&month=${month + 1}&year=${year}&account=${account}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!resp.ok) {
    throw new Error('Failed to fetch logs');
  }
  return await resp.json();
};

export const getImages = async (agentId, account, date, token) => {
  const resp = await baseAPI.get(
    `/tracker/images?agentId=${agentId}&date=${date}&account=${account}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!resp.ok) {
    throw new Error('Failed to fetch image');
  }

  return await resp.json();
};

export const displayImageUrlsApi = async (urls, token) => {
  const resp = await baseAPI.post(
    '/aws/s3/generate-multiple-download-urls',
    {
      keys: urls,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!resp.ok) {
    throw new Error('Failed to fetch image');
  }

  return await resp.json();
};

export const updateTimeLogApi = async ({
  date,
  month,
  year,
  duration,
  agentId,
  account,
  token,
}) => {
  const resp = await baseAPI.put(
    '/tracker/logs',
    { date, month, year, duration, agentId, account },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
  if (!resp.ok) {
    throw new Error('Failed to create new log');
  }
  return await resp.json();
};

export const getTimeTrackerAccessApi = async (token) => {
  const resp = await baseAPI.get(`/tracker/access`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!resp.ok) {
    throw new Error('Failed to fetch access list');
  }
  return await resp.json();
};
