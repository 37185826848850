import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { useTimeTrackerStore } from '../../../../../../state';
import { getMonthDays } from '../../../../../../utils/helpers';
import CalendarDay from '../CalendarDay';
import CalendarShimmer from '../CalendarShimmer';
import SetTimeLogModal from './setTimeLog';
const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const { month, year, updateState, isMonthlyLogsLoading, isAgentLoading, updateTimeLog } =
    useTimeTrackerStore(
      (state) => ({
        month: state.month,
        year: state.year,
        updateState: state.updateState,
        isMonthlyLogsLoading: state.isMonthlyLogsLoading,
        isAgentLoading: state.isAgentLoading,
        updateTimeLog: state.updateTimeLog,
      }),
      shallow
    );

  useEffect(() => {
    updateState('month', currentDate.getMonth());
    updateState('year', currentDate.getFullYear());
  }, [currentDate]);

  const { days, firstDayOfWeek, emptyEndDays } = getMonthDays(year, month);
  const threeMonthsAgo = new Date(
    new Date().setMonth(new Date().getMonth() - 3)
  );
  const prevMonth = () => {
    const newDate = new Date(year, month - 1, 1);
    if (newDate >= threeMonthsAgo) {
      setCurrentDate(newDate);
    }
  };

  const nextMonth = () => {
    const newDate = new Date(year, month + 1, 1);
    if (newDate <= new Date()) {
      setCurrentDate(newDate);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSetTimeLog = async ({
    date,
    month,
    year,
    duration,
  }) => {
    setIsLoading(true);
    try {
      await updateTimeLog({
        date,
        month,
        year,
        duration,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white p-4">
      <div className="flex space-x-2 items-center mb-4 font-bold justify-between">
        <div className="flex items-center space-x-2">
          <span className="text-xl px-4">
            {currentDate.toLocaleString('default', { month: 'long' })} {year}
          </span>
          <button onClick={prevMonth} className="p-2 text-lg rounded ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M1.70857e-07 7C1.62055e-07 7.20137 0.0817734 7.40274 0.237189 7.5397L6.72394 13.7825C6.86299 13.9194 7.04294 14 7.24746 14C7.6728 14 8 13.6778 8 13.2589C8 13.0656 7.91819 12.8723 7.78735 12.7353L1.83232 7L7.78735 1.26468C7.91819 1.12772 8 0.942466 8 0.741098C8 0.322209 7.6728 -1.43023e-08 7.24746 -3.28946e-08C7.04294 -4.18345e-08 6.86299 0.0805609 6.71577 0.217487L0.237189 6.4603C0.0817735 6.61335 1.80013e-07 6.79055 1.70857e-07 7Z"
                fill="#394DC6"
              />
            </svg>{' '}
          </button>
          <button onClick={nextMonth} className="p-2 text-lg rounded ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M8 7C8 7.20137 7.91823 7.40274 7.76281 7.5397L1.27606 13.7825C1.13701 13.9194 0.957063 14 0.752541 14C0.3272 14 5.97875e-07 13.6778 5.79565e-07 13.2589C5.71114e-07 13.0656 0.0818087 12.8723 0.212654 12.7353L6.16768 7L0.212653 1.26468C0.0818082 1.12772 4.11965e-08 0.942466 3.23944e-08 0.741098C1.40842e-08 0.322209 0.327199 -1.43023e-08 0.752541 -3.28946e-08C0.957062 -4.18345e-08 1.13701 0.0805609 1.28423 0.217487L7.76281 6.4603C7.91823 6.61335 8 6.79055 8 7Z"
                fill="#394DC6"
              />
            </svg>{' '}
          </button>
        </div>
        <div className="flex items-center space-x-2 text-white">
          <button
            onClick={openModal}
            className="cursor-pointer border-none px-4 py-2 bg-slateblue rounded-full flex items-center justify-center hover:bg-royalblue-200"
          >
            Set Time Log
          </button>
        </div>
      </div>

      {isMonthlyLogsLoading || isAgentLoading ? (
        <CalendarShimmer daysInRow={7} />
      ) : (
        <div className="grid grid-cols-7 border">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div
              key={day}
              className="text-center font-semibold bg-bgGray border border-disableColor p-2"
            >
              {day}
            </div>
          ))}
          {Array.from({ length: firstDayOfWeek }).map((_, index) => (
            <div
              key={`empty-start-${index}`}
              className="border border-disableColor"
            ></div>
          ))}
          {days.map((day) => (
            <CalendarDay
              key={day.day}
              day={day.day}
              year={year}
              month={month}
            />
          ))}
          {Array.from({ length: emptyEndDays }).map((_, index) => (
            <div
              key={`empty-end-${index}`}
              className="border border-disableColor"
            ></div>
          ))}
        </div>
      )}
      <SetTimeLogModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleSetTimeLog}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Calendar;
