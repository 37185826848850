import { useTimeTrackerStore } from '../../../../../state';
import { formatWorkTime } from '../../../../../utils/helpers';

const Overview = () => {
  const dailyLogs = useTimeTrackerStore((state) => state.dailyLogs);
  const selectedAgent = useTimeTrackerStore((state) => state.selectedAgent);
  const agents = useTimeTrackerStore((state) => state.agents);

  const agentName = agents?.find((agent) => agent._id === selectedAgent)?.name;

  let totalHours = 0;

  dailyLogs.forEach((log) => {
    totalHours += log.duration;
  });

  const totalSpend = '100';
  return (
    <>
      <div className="text-neutral-700 text-sm font-bold font-inter capitalize  ">
        overview - Month To Date
      </div>
      <div className="w-full flex  items-center p-5  bg-white shadow my-3 gap-10">
        <div>
          <h2 className="text-md font-semibold text-stone-400">Agent</h2>
          <p className="text-xl font-bold">{agentName}</p>
        </div>
        <div>
          <h2 className="text-md font-semibold text-stone-400">
            Total Working Hours
          </h2>
          <p className="text-xl font-bold">{formatWorkTime(totalHours)}</p>
        </div>
        {/* <div className="ml-5">
          <h2 className="text-md font-semibold text-stone-400">Total Spend</h2>
          <p className="text-xl font-bold">${totalSpend}</p>
        </div> */}
      </div>
    </>
  );
};

export default Overview;